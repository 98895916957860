/*
 * Copyright (2019) All Rights Reserved to Corner Edge Projects.
 * All Content Designed and Developed By Kyp Emmanuel.
 *
 */

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA0p6JUO2xJLs3iD5VDGCQ7-9O_yPMZpOM',
    authDomain: 'black-wolf-fitness.firebaseapp.com',
    databaseURL: 'https://black-wolf-fitness.firebaseio.com',
    projectId: 'black-wolf-fitness',
    storageBucket: 'black-wolf-fitness.appspot.com',
    messagingSenderId: '226787276864',
    appId: '1:226787276864:web:ac8f3a5f657627675c07a5',
    measurementId: 'G-B6WGT0ZE1Q'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
