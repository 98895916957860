import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';

// tslint:disable-next-line:class-name
export interface verifyModel {
  mode?: string;
  oobCode?: string;
  apiKey?: string;
  continueUrl?: string;
  lang?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'black-wolf-fitness-verfiy';
  loading: boolean = true;
  verification: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private afAuth: AngularFireAuth) {
    console.log(this.route.snapshot);
  }

  public verifyModel(verifyData: ParamMap): verifyModel {
    if (verifyData.has('oobCode') &&
      verifyData.has('mode') &&
      verifyData.has('apiKey') &&
      verifyData.has('continueUrl') &&
      verifyData.has('lang')
    ) {
      return {
        mode: verifyData.get('mode'),
        oobCode: verifyData.get('oobCode'),
        apiKey: verifyData.get('apiKey'),
        continueUrl: verifyData.get('continueUrl'),
        lang: verifyData.get('lang')
      };
    } else {
      return null;
    }
  }

  verify() {
  }

  async ngOnInit() {

  }

  async ngAfterViewInit() {
    this.route.queryParamMap.subscribe(async queryParams => {
      console.log(queryParams);
      var verifyData = await this.verifyModel(queryParams);
      /** Check that Verify Data isn't null */
      if (verifyData != null) {
        console.log(verifyData.oobCode);
        await this.afAuth.auth.checkActionCode(verifyData.oobCode).then(async (info) => {
          console.log(info);
          await this.afAuth.auth.applyActionCode(verifyData.oobCode).then((resp) => {
            this.verification = true;
            console.log(resp);
            console.log('Accepted Response');
          }).catch((e) => {
            console.log(e);
            console.log('error');
            this.verification = false;
          });
        }).catch((e) => {
          console.log(e);
          console.log('error');
          this.verification = false;
        });
        console.log(verifyData);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      } else {
        console.log('fails');
      }


    });
    console.log(this.route.snapshot);
  }


  // const link = "https://us-central1-black-wolf-fitness.cloudfunctions.net/verifyEmail?mode=" + encodeURIComponent(mode) + "&oobCode=" + encodeURIComponent(oobCode) + "&apiKey=" + encodeURIComponent(apiKey) + "&continueUrl=" + encodeURIComponent(continueUrl) + "&lang=" + encodeURIComponent(lang);
  // return db.collection("users")
  //     .where("verificationLink", "==", link)
  //     .get()
  //     .then(function (querySnapshot) {
  //         querySnapshot.forEach(function (user) {
  //             const userData: any = user.data();
  //             console.log("email verified: ", userData.userId);
  //             return admin.auth().updateUser(userData.userId, {
  //                 emailVerified: true
  //             }).then(function (userRecord) {
  //                 return db.collection('users').doc(userData.userId).set({emailVerified: true}, {merge: true});
  //             });
  //         });
  //         return res.sendStatus(200).end();
  //     }).catch(function (err) {
  //         console.log("error:", err);
  //         return res.sendStatus(403).end();
  //     });


}
